import { get } from '@/infra/rest';
import { getSessionJWT } from '@/infra/stytch';
import { updateAgentSessionStep } from '@/modules/sessions/requests';
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  CommonRagCreateRequest,
} from '@/modules/sessions/types';
import { getSignedUrl } from '@/shared/requests/get-signed-url';
import { uploadFileReq } from '@/shared/requests/upload-file';
import { userStateSelector } from '@/shared/states/user';
import { AiResponseType } from '@/shared/types/user';
import { NavigateFunction } from 'react-router-dom';
import { emitRagCreate } from '../requests';
import {
  getAgentData,
  getAgentStateActions,
  getAgentStepData,
} from '../states';
import {
  Control,
  getCustomizeControls,
  getCustomizeControlsActions,
} from '../states/cusomize-controls';
import { AGENT_TYPES, GapAssessmentTypes } from '../types';
import { getResponseTypes } from '../utils/get-response-type';
import { handleSocketResponse } from '../utils/socket-response';

export const handleContinueCustomizeControls = async (
  steps: AgentSessionStep[]
) => {
  const customizeControlStep = steps.find(
    (step) => step.type === AgentSessionStepType.CONTROL_SELECTION
  );

  if (
    customizeControlStep &&
    customizeControlStep.status === AgentSessionStatus.INPUT_NEEDED
  ) {
    await processFileForCustomizeControls(customizeControlStep.data.url);
  }
};

export const processFileForCustomizeControls = async (url: string) => {
  let customizeControlJson = await get<Control[]>({
    url,
    isAuthRequired: false,
  });

  customizeControlJson = customizeControlJson.map((control) => ({
    ...control,
    selected:
      control.selected !== undefined
        ? control.selected
        : control.edited_state === 'default'
          ? true
          : false,
  }));

  const { setDataState, setSelectedControls } = getCustomizeControlsActions();

  setDataState(customizeControlJson);
  setSelectedControls(
    new Set(
      customizeControlJson
        .filter((control) =>
          control.selected !== undefined
            ? control.selected
            : control.edited_state === 'default'
        )
        .map((control) => control.uid)
    )
  );
};

export const handleAutoSaveCustomizeControls = async (
  agentId: string,
  markAsComplete = false
) => {
  const controls = JSON.stringify(getCustomizeControls());
  const blob = new Blob([controls], { type: 'application/json' });

  const stepData = getAgentStepData(agentId);

  const { updateAgentStepData } = getAgentStateActions();

  const customizeControlStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.CONTROL_SELECTION
  );

  if (stepData && customizeControlStep) {
    const stepUrl = customizeControlStep.data.url;
    let currentStaleUrl = stepUrl;
    let markAsCompleteDone = false;

    if (!stepUrl.includes('signed_urls')) {
      const signedUrl = await getSignedUrl({
        file_names: [stepUrl],
        max_age: 86400,
      });
      const updatedStep = {
        ...customizeControlStep,
        data: {
          url: signedUrl[0],
        },
        status: markAsComplete
          ? AgentSessionStatus.COMPLETE
          : AgentSessionStatus.IN_PROGRESS,
      };
      const updatedSteps = stepData.map((step) => {
        if (step.id === updatedStep.id) {
          return updatedStep;
        }
        return step;
      });
      updateAgentStepData(agentId, updatedSteps);
      await updateAgentSessionStep(updatedStep);
      currentStaleUrl = signedUrl[0];
      markAsCompleteDone = true;
    }

    const expiryDate = new URL(currentStaleUrl).searchParams.get('se');
    if (expiryDate) {
      const expiry = new Date(expiryDate);
      const currentTime = new Date();
      const diff = expiry.getTime() - currentTime.getTime();
      if (diff < 0) {
        const signedUrl = await getSignedUrl({
          stale_urls: [currentStaleUrl],
          max_age: 86400,
        });
        currentStaleUrl = signedUrl[0];
      }
    }
    await uploadFileReq(currentStaleUrl, blob);

    if (markAsComplete && !markAsCompleteDone) {
      const updatedStep = {
        ...customizeControlStep,
        data: {
          url: currentStaleUrl,
        },
        status: AgentSessionStatus.COMPLETE,
      };
      await updateAgentSessionStep(updatedStep);
      const updatedStepData = stepData.map((step) => {
        if (step.id === updatedStep.id) {
          return updatedStep;
        }
        return step;
      });
      updateAgentStepData(agentId, updatedStepData);
    }
  }
};

export const handleGenerateResponse = async (
  agentId: string,
  navigate: NavigateFunction
) => {
  const agentData = getAgentData<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2
  >(agentId);

  if (!agentData) {
    throw new Error('An error occurred');
  }
  const { updateAgentStepData } = getAgentStateActions();

  const selectedResponse = userStateSelector.getState().aiResponseType === AiResponseType.NONE ? AiResponseType.LITE:userStateSelector.getState().aiResponseType;
  const task = agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1? 'soc2_type1' : 'soc2_type2';
  const statusInProgressSessionStep = [AgentSessionStepType.SOC2_TYPE2_AUDIT,AgentSessionStepType.SOC2_TYPE2,AgentSessionStepType.SOC2_TYPE1];

  const statusCompleteSessionSteps = [AgentSessionStepType.CONTROL_SELECTION];

  const steps = agentData.stepData.reduce((acc, step) => {
    if (statusCompleteSessionSteps.includes(step.type)) {
      step.status = AgentSessionStatus.COMPLETE;
    }

    if (statusInProgressSessionStep.includes(step.type)) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }

    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);
  const customizeControlStep = steps.find(
    (step) => step.type === AgentSessionStepType.CONTROL_SELECTION
  );
  const { response_mode, response_quality } =
    getResponseTypes(selectedResponse);
  if (customizeControlStep) {
  
    const source_urls= agentData.sessionData.type === AgentSessionType.SOC2_GAP ? agentData.sessionData.soc2_type2_audit.source_urls : agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1 ? agentData.sessionData.soc2_type1.source_urls : agentData.sessionData.soc2_type2.source_urls
    
    const gapAssessmentRequest: CommonRagCreateRequest = {
      doc_type: 'json',
      response_quality,
      controls_url: customizeControlStep?.data?.url??'',
      source_urls,
    };

    const agent_session_step_id =
      steps.find((step) => statusInProgressSessionStep.includes(step.type ))?.id ?? '';
    
      updateAgentStepData(agentId, steps);
      
    await updateAgentSessionStep(customizeControlStep);
    
    await emitRagCreate(
      {
        agent_session_id: agentId,
        agent_session_step_id,
        token: await getSessionJWT(),
        rag_input: gapAssessmentRequest,
        response_mode,
        task: task,
      },
      (response: any) => {
        handleSocketResponse(response, navigate);
      }
    );
  }
};
