/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { Member, PermissionsMap } from "@stytch/vanilla-js";
import { create } from "zustand";
import { TeamRole } from "../types";
import {
  canAccessQualityAccuracy,
  canAccessQualitySpeed,
  canAccessTeamSearch,
  canAccessZaniaAccurateResponse,
  canAccessZaniaAgent,
  canAccessZaniaDoraAssessment,
  canAccessZaniaFileUpload,
  canAccessZaniaGapAssessment,
  canAccessZaniaQaAssessment,
  canAccessZaniaRiskAssessment,
  canAccessZaniaVendorAssessment,
  isTeamAdmin,
  isTeamMember,
  isTeamOrgAdmin,
} from "../utils";

interface TeamState {
  team: Member[];
  permissions: PermissionsMap<Record<string, string>>;
}

interface TeamActions {
  setTeam: (team: Member[]) => void;
  getMember: (memberId: string) => Member | undefined;
  updateMember: (member: Member) => void;
  removeMember: (memberId: string) => void;
  addMember: (member: Member) => void;
  setPermissions: (permissions: PermissionsMap<Record<string, string>>) => void;
}

type TeamStore = TeamState & {
  actions: TeamActions;
};

const initialState: TeamState = {
  team: [],
  permissions: {},
};

const useTeamStore = create<TeamStore>((set, get) => ({
  ...initialState,
  actions: {
    setTeam: (team) => set({ team }),
    getMember: (memberId) => {
      const { team } = get();
      return team.find((member) => member.member_id === memberId);
    },
    updateMember: (member) =>
      set((state) => ({
        team: state.team.map((teamMember) =>
          teamMember.member_id === member.member_id ? member : teamMember,
        ),
      })),
    removeMember: (memberId) =>
      set((state) => ({
        team: state.team.filter((member) => member.member_id !== memberId),
      })),
    addMember: (member) =>
      set((state) => ({
        team: [...state.team, member],
      })),
    setPermissions: (permissions) =>
      set((state) => ({
        permissions: { ...state.permissions, ...permissions },
      })),
  },
}));

export const useTeam = () => useTeamStore();
export const useTeamState = () => useTeamStore((state) => state);
export const useTeamActions = () => useTeamStore((state) => state.actions);
export const setTeam = useTeamStore.getState().actions.setTeam;
export const getMember = useTeamStore.getState().actions.getMember;
export const updateMember = useTeamStore.getState().actions.updateMember;
export const removeMember = useTeamStore.getState().actions.removeMember;
export const addMember = useTeamStore.getState().actions.addMember;

export const getZaniaRoleLabelMap = (): {
  roleId: TeamRole;
  label: string;
  description: string;
}[] => {
  return [
    // {
    //   roleId: TeamRole.ORG_ADMIN,
    //   label: 'Owner',
    //   description:
    //     'Allows them to add or update team members and admins and edit their privileges',
    // },
    {
      roleId: TeamRole.ADMIN,
      label: "Admin",
      description:
        "Allows them to add or update team members and edit their privileges",
    },
    {
      roleId: TeamRole.MEMBER,
      label: "Member",
      description:
        "Allows them to access and control the features assigned by admins and owner",
    },
    {
      roleId: TeamRole.ZANIA_AGENT,
      label: "Agent",
      description: "",
    },
    {
      roleId: TeamRole.RESPONSE_QUALITY_ACCURACY,
      label: "Accuracy",
      description: "Use a premium model and generate a fully complete report",
    },
    {
      roleId: TeamRole.ZANIA_RISK_ASSESSMENT,
      label: "Risk Assessment",
      description:
        "Zania will compile evidences required to fill risk assessment reports",
    },
    {
      roleId: TeamRole.ZANIA_DORA_ASSESSMENT,
      label: "Gap Assessment",
      description:
        "Zania will compile evidences required to conduct gap assessment.",
    },
    {
      roleId: TeamRole.ZANIA_GAP_ASSESSMENT,
      label: "Gap Assessment",
      description:
        "Zania will compile evidences required to conduct gap assessment.",
    },
    {
      roleId: TeamRole.ZANIA_FILE_UPLOAD,
      label: "File Upload",
      description: "",
    },
    {
      roleId: TeamRole.ZANIA_QA_ACCESS,
      label: "Security Questionnaire",
      description:
        "Zania will refer to your knowledge base and auto-fill a new questionnaire",
    },
    {
      roleId: TeamRole.RESPONSE_QUALITY_SPEED,
      label: "Speed",
      description:
        "Use a premium model and generate a partially complete report",
    },
    {
      roleId: "lite" as TeamRole,
      label: "Lite",
      description: "",
    },
    {
      roleId: TeamRole.ZANIA_VENDOR_ASSESSMENT,
      label: "Third-Party Risk Assessment",
      description: "",
    },
  ];
};

export const getZaniaTeamMemberRoles = (memberId: string) => {
  const teamMember = getMember(memberId);
  const roles =
    teamMember?.roles?.map?.(({ role_id }) => role_id as TeamRole) ?? [];
  return {
    isTeamAdmin: isTeamAdmin(roles).hasAccess,
    isTeamMember: isTeamMember(roles).hasAccess,
    isTeamOrgAdmin: isTeamOrgAdmin(roles).hasAccess,
    canAccessZaniaAgent: canAccessZaniaAgent(roles).hasAccess,
    canAccessZaniaAccurateResponse:
      canAccessZaniaAccurateResponse(roles).hasAccess,
    canAccessZaniaRiskAssessment: canAccessZaniaRiskAssessment(roles).hasAccess,
    canAccessZaniaGapAssessment: canAccessZaniaGapAssessment(roles).hasAccess,
    canAccessZaniaVendorAssessment:
      canAccessZaniaVendorAssessment(roles).hasAccess,
    canAccessZaniaFileUpload: canAccessZaniaFileUpload(roles).hasAccess,
    canAccessZaniaQaAssessment: canAccessZaniaQaAssessment(roles).hasAccess,
    canSearchTeamMembers: canAccessTeamSearch(roles).hasAccess,
  };
};

export const getZaniaTeamMemberAccess = (memberId: string) => {
  const teamMemberRoles: string[] = [];
  const teamMember = getMember(memberId);
  const zaniaRoleLabelMap = getZaniaRoleLabelMap();
  const roles =
    teamMember?.roles?.map?.(({ role_id }) => role_id as TeamRole) ?? [];

  const zaniaAgent = canAccessZaniaAgent(roles);
  const zaniaAccurateResponse = canAccessZaniaAccurateResponse(roles);
  const zaniaRiskAssessment = canAccessZaniaRiskAssessment(roles);
  const zaniaDoraAssessment = canAccessZaniaDoraAssessment(roles);
  const zaniaVendorAssessment = canAccessZaniaVendorAssessment(roles);
  const zaniaFileUpload = canAccessZaniaFileUpload(roles);
  const zaniaQaAccess = canAccessZaniaQaAssessment(roles);
  const zaniaQualityAccuracy = canAccessQualityAccuracy(roles);
  const zaniaQualitySpeed = canAccessQualitySpeed(roles);
  const zaniaGapAssessment = canAccessZaniaGapAssessment(roles);

  if (zaniaAgent.hasAccess) {
    teamMemberRoles.push(zaniaAgent.roleId);
  }
  if (zaniaAccurateResponse.hasAccess) {
    teamMemberRoles.push(zaniaAccurateResponse.roleId);
  }
  if (zaniaRiskAssessment.hasAccess) {
    teamMemberRoles.push(zaniaRiskAssessment.roleId);
  }
  if (zaniaDoraAssessment.hasAccess) {
    teamMemberRoles.push(zaniaDoraAssessment.roleId);
  }
  if (zaniaVendorAssessment.hasAccess) {
    teamMemberRoles.push(zaniaVendorAssessment.roleId);
  }
  if (zaniaFileUpload.hasAccess) {
    teamMemberRoles.push(zaniaFileUpload.roleId);
  }
  if (zaniaQaAccess.hasAccess) {
    teamMemberRoles.push(zaniaQaAccess.roleId);
  }
  if (zaniaQualityAccuracy.hasAccess) {
    teamMemberRoles.push(zaniaQualityAccuracy.roleId);
  }
  if (zaniaQualitySpeed.hasAccess) {
    teamMemberRoles.push(zaniaQualitySpeed.roleId);
  }
  if (zaniaGapAssessment.hasAccess) {
    teamMemberRoles.push(zaniaGapAssessment.roleId);
  }
  

  return teamMemberRoles.map((roleId) => ({
    roleId,
    label: zaniaRoleLabelMap.find((role) => role.roleId === roleId)
      ?.label as string,
  }));
};
