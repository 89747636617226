/* eslint-disable @typescript-eslint/unbound-method */
import { get } from "@/infra/rest";
import { getSessionJWT } from "@/infra/stytch";
import {
  createAgentSession,
  getAgentSession,
  updateAgentSessionStep,
} from "@/modules/sessions/requests";
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  CommonRagCreateRequest,
  RiskAgentSession,
} from '@/modules/sessions/types';
import { AGENT_ROUTES, ROUTES } from "@/shared/constants/routes";
import { getSignedUrl } from "@/shared/requests/get-signed-url";
import { uploadFileReq } from "@/shared/requests/upload-file";
import { addNotification } from "@/shared/states/notification";
import { userStateSelector } from "@/shared/states/user";
import { AiResponseType } from "@/shared/types/user";
import { NavigateFunction } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { emitRagCreate, getRAGExcelFromJSON } from "../requests";
import { getAgentData, getAgentStateActions } from "../states";
import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  AgentSpecificReviewResponseType,
  ConfidenceTypes,
  ReviewResponseData,
  ReviewSourceTypes,
  RiskAssessmentTypes,
  RiskReviewResponseTableRow,
} from "../types/index.ts";
import {
  ResponseSchemaVersion,
  RiskReviewResponse,
} from '../types/risk-and-gap.ts';
import { AutoSaveFunctionArgs } from "../utils/autosave";
import { getFileNameForDownloadReport } from "../utils/downloadReport.ts";
import { getRenderType } from "../utils/get-render-type";
import { getResponseTypes } from "../utils/get-response-type";
import { getFieldTitle } from "../utils/getFieldTitle";
import { getTagAndRiskString } from "../utils/risk-control";
import { handleSocketResponse } from "../utils/socket-response";

interface ProcessFileForRiskAssessmentArgs {
  name: string;
  sourceFiles: AgentSourceFile[];
  navigate: NavigateFunction;
}

export const processFileForRiskAssessment = async ({
  name,
  sourceFiles,
  navigate,
}: ProcessFileForRiskAssessmentArgs) => {
  const selectedResponse = userStateSelector.getState().aiResponseType === AiResponseType.NONE ? AiResponseType.LITE:userStateSelector.getState().aiResponseType;
  const source_urls = sourceFiles.map((file) => file.url);
  const { response_mode, response_quality } =
    getResponseTypes(selectedResponse);

  const riskAssessment: CommonRagCreateRequest = {
    doc_type: "json",
    response_quality,
    source_urls,
  };

  const {
    data: { session, steps = [] },
  } = await createAgentSession({
    name: name ?? "Risk Assessment",
    type: AgentSessionType.RISK_ASSESSMENT,
    [AgentSessionType.RISK_ASSESSMENT]: riskAssessment,
  });

  if (!session || !session.id) {
    throw new Error("An error occurred");
  }

  const stepData = steps.reduce((acc, step) => {
    if (
      [
        AgentSessionStepType.LOAD_TEMPLATE,
        AgentSessionStepType.EXTRACT_CONTROLS,
      ].includes(step.type)
    ) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }
    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  const agentData: AgentData<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.NIST_CSF_2
  > = {
    agentType: AGENT_TYPES.RISK_ASSESSMENT,
    subType: RiskAssessmentTypes.NIST_CSF_2,
    sessionData: session as RiskAgentSession,
    stepData,
    responseQuality: selectedResponse,
    mainData: {
      sourceFilesUrls: new Map(
        sourceFiles.map((file) => [file.fileName, file]),
      ),
      approvedIds: [],
      editedIds: [],
    },
  };

  const agent_session_step_id =
    steps.find(
      (step) =>
        step.type === AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE,
    )?.id ??
    steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE)
      ?.id ??
    "";

  const { setAgentData } = getAgentStateActions();
  setAgentData(session.id, agentData);

  await emitRagCreate(
    {
      agent_session_id: session.id,
      agent_session_step_id,
      token: getSessionJWT(),
      rag_input: riskAssessment,
      response_mode,
      task: "nist_csf_2_risk_assessment",
    },
    (response: any) => {
      handleSocketResponse(response, navigate);
    },
  );
  navigate({
    pathname: `/agent/${session.id}/`,
  });
};

interface FinalRiskReviewResponse extends RiskReviewResponse {
  approved: boolean;
  edited: boolean;
  id: string;
}

// BOOKMARK - THIS IS WHERE THE JSON IS GENERATED
export const generateFinalRiskJSON = (agentId: string) => {
  const agentData = getAgentData<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.NIST_CSF_2
  >(agentId);

  if (!agentData) {
    throw new Error("An error occurred");
  }

  const { mainData } = agentData;
  const { reviewResponseData } = mainData;

  if (!reviewResponseData) {
    throw new Error("An error occurred");
  }

  const approvedIds = mainData.approvedIds;
  const editedIds = mainData.editedIds;
  const finalRiskJson: FinalRiskReviewResponse[] = [];

  reviewResponseData.forEach((item, id) => {
    const risk: FinalRiskReviewResponse = {
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
      id,
      subcategory: item.find((item) => item.key === "subcategory")
        ?.value as string,
      function: item.find((item) => item.key === "function")?.value as string,
      category: item.find((item) => item.key === "category")?.value as string,
      observations: item.find((item) => item.key === "observations")
        ?.value as string,
      questions: item.find((item) => item.key === "questions")?.value as string,
      sources: item.find((item) => item.key === "sources")
        ?.value as ReviewSourceTypes[],
      confidence: item.find((item) => item.key === "confidence")
        ?.value as ConfidenceTypes,
      justification: item.find((item) => item.key === "justification")
        ?.value as string,
      gaps: item.find((item) => item.key === "gaps")?.value as string,
      improvement_opportunities: item.find(
        (item) => item.key === "improvement_opportunities",
      )?.value as string,
      risk_statement: item.find((item) => item.key === "risk_statement")
        ?.value as string,
      recommendations: item.find((item) => item.key === "recommendations")
        ?.value as string,
      improvement_recommendations: item.find(
        (item) => item.key === "improvement_recommendations",
      )?.value as string,
      risk_value: item.find((item) => item.key === "risk_value")
        ?.value as number,
      impact: item.find((item) => item.key === "impact")?.value as number,
      likelihood: item.find((item) => item.key === "likelihood")
        ?.value as number,
      impact_label: item.find((item) => item.key === "impact_label")
        ?.value as string,
      likelihood_label: item.find((item) => item.key === "likelihood_label")
        ?.value as string,
      control_id: item.find((item) => item.key === "control_id")
        ?.value as string,
      zania_control_id: item.find((item) => item.key === "zania_control_id")
        ?.value as string,
      current_maturity_level: item.find(
        (item) => item.key === "current_maturity_level",
      )?.value as string,
      description_of_current_maturity_level: item.find(
        (item) => item.key === "description_of_current_maturity_level",
      )?.value as string,
      path_to_next_maturity_level: item.find(
        (item) => item.key === "path_to_next_maturity_level",
      )?.value as string,
      version: item.find((item) => item.key === "version")
        ?.value as ResponseSchemaVersion,
    };
    finalRiskJson.push(risk);
  });
  return finalRiskJson;
};

export const handleRiskAssessmentAutoSave = async ({
  agentId,
  markAsComplete,
}: AutoSaveFunctionArgs) => {
  const risks = JSON.stringify(generateFinalRiskJSON(agentId));
  const blob = new Blob([risks], { type: "application/json" });
  const agentData = getAgentData<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.NIST_CSF_2
  >(agentId);
  if (!agentData) {
    throw new Error("An error occurred");
  }
  const { stepData } = agentData;
  const { staleUrl } = agentData.mainData;

  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();

  const editStepData = stepData?.find(
    (step) => step.type === AgentSessionStepType.EDIT_RESPONSE,
  );

  if (!editStepData) {
    throw new Error("An occurred while saving");
  }

  const stepUrl = editStepData?.data?.url;

  let currentStaleUrl = staleUrl || stepUrl;
  let markAsCompleteDone = false;

  if (!currentStaleUrl) {
    const signedUrl = await getSignedUrl({
      file_names: ["updated_risks.json"],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete
        ? AgentSessionStatus.COMPLETE
        : AgentSessionStatus.INPUT_NEEDED,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }

  const expiryDate = new URL(currentStaleUrl).searchParams.get("se");
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }
  await uploadFileReq(currentStaleUrl, blob);
  if (markAsComplete && !markAsCompleteDone) {
    const updatedStep = {
      ...editStepData,
      data: {
        url: currentStaleUrl,
      },
      status: AgentSessionStatus.COMPLETE,
    };
    await updateAgentSessionStep(updatedStep);
    const updatedStepData = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedStepData);
  }
};

// BOOKMARK - THIS IS WHERE THE JSON IS PROCESSED
export const processFileForRiskReview = async (
  url: string,
  sessionId: string,
) => {
  const riskJson = await get<FinalRiskReviewResponse[]>({
    url,
    isAuthRequired: false,
  });

  const session = await getAgentSession(sessionId ?? "");
  const ids: string[] = [];
  const keysToOmit: string[] = [
    "id",
    "confidence",
    "subcategory",
    "function",
    "category",
    "approved",
    "edited",
    "questions",
    "impact",
    "likelihood",
    "impact_label",
    "likelihood_label",
    "control_id",
    "zania_control_id",
    "justification",
    "version",
    "justification",
  ];
  const tableRows: RiskReviewResponseTableRow[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];
  const reviewResponse = new Map<
    string,
    ReviewResponseData<
      keyof AgentSpecificReviewResponseType<
        AGENT_TYPES.RISK_ASSESSMENT,
        RiskAssessmentTypes.NIST_CSF_2
      >
    >[]
  >(
    riskJson.map((risk) => {
      const id = uuid();
      ids.push(id);
      const { tag, riskString } = getTagAndRiskString(risk.subcategory);
      const tableRow: RiskReviewResponseTableRow = {
        id: id,
        tag,
        riskString,
        riskValue: risk.risk_value ?? "",
        confidence: risk.confidence ?? "",
        sources: risk.sources ?? [],
        status: risk.approved ? "approved" : risk.edited ? "edited" : "none",
      };
      tableRows.push(tableRow);
      if (risk.approved) {
        approvedIds.push(id);
      }
      if (risk.edited) {
        editedIds.push(id);
      }

      const orderedKeys = [
        "risk_value",
        "risk_statement",
        "observations",
        "gaps",
        "recommendations",
        "improvement_opportunities",
        "improvement_recommendations",
        "current_maturity_level",
        "description_of_current_maturity_level",
        "path_to_next_maturity_level",
        "sources",
      ];

      const orderedData = orderedKeys.map((key) => ({
        type: getRenderType(key, risk.version),
        value: risk[key as keyof typeof risk],
        key: key as keyof AgentSpecificReviewResponseType<
          AGENT_TYPES.RISK_ASSESSMENT,
          RiskAssessmentTypes.NIST_CSF_2
        >,
        title: getFieldTitle(key, risk.version),
        shouldRender: !keysToOmit.includes(key),
      }));

      Object.entries(risk).forEach(([key, value]) => {
        if (orderedKeys.includes(key)) {
          return;
        }
        const data: ReviewResponseData<
          keyof AgentSpecificReviewResponseType<
            AGENT_TYPES.RISK_ASSESSMENT,
            RiskAssessmentTypes.NIST_CSF_2
          >
        > = {
          type: getRenderType(key, risk.version),
          value: value as unknown,
          key: key as keyof AgentSpecificReviewResponseType<
            AGENT_TYPES.RISK_ASSESSMENT,
            RiskAssessmentTypes.NIST_CSF_2
          >,
          title: getFieldTitle(key, risk.version),
          shouldRender: !keysToOmit.includes(key),
        };
        orderedData.push(data);
      });
      return [id, orderedData];
    }),
  );

  const currentData = getAgentData<
    AGENT_TYPES.RISK_ASSESSMENT,
    RiskAssessmentTypes.NIST_CSF_2
  >(sessionId);

  if (!currentData) {
    throw new Error(
      "An error occurred while processing the file: Failed to get agent data.",
    );
  }

  const stepData = currentData.stepData.map((step) => {
    if (step.type === AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
    }
    return step;
  });

  const agentData: Partial<
    AgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.NIST_CSF_2>
  > = {
    agentType: AGENT_TYPES.RISK_ASSESSMENT,
    sessionData: session.data.session as RiskAgentSession,
    stepData,
    mainData: {
      ...currentData.mainData,
      reviewResponseData: reviewResponse,
      reviewResponseIds: ids,
      tableRows,
      searchFor: ["riskString"],
      searchTerm: "",
      approvedIds,
      editedIds,
    },
  };

  const { updateAgentData } = getAgentStateActions();

  updateAgentData<AGENT_TYPES.RISK_ASSESSMENT, RiskAssessmentTypes.NIST_CSF_2>(
    sessionId,
    agentData,
  );
};

export const getRiskJsonFromApi = async (
  sessionId: string,
  responsesJsonFileUrl: string,
) => {
  try {
    const agentData = getAgentData<
      AGENT_TYPES.RISK_ASSESSMENT,
      RiskAssessmentTypes.NIST_CSF_2
    >(sessionId);
    if (!agentData) {
      throw new Error("An error occurred");
    }
    const { responseQuality, sessionData, stepData } = agentData;

    const data = await getRAGExcelFromJSON({
      task: "nist_csf_2_risk_assessment",
      output_format: "excel",
      json_url: responsesJsonFileUrl,
      response_quality: responseQuality ?? AiResponseType.LITE,
    });

    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    setFinalFileUrl(sessionId, data.data.file_url);

    const response = await fetch(data.data.file_url);
    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = getFileNameForDownloadReport(sessionData);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: "success",
      title: "Downloading your Report...",
      message:
        "If your report download does not start within 15 seconds, please use the download button",
    });

    const downloadStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
    );
    const editResponseStep = stepData?.find(
      (step) => step.type === AgentSessionStepType.EDIT_RESPONSE,
    );
    const updatedStepData = stepData.map((step) => {
      if (step.id === editResponseStep?.id) {
        return { ...step, status: AgentSessionStatus.COMPLETE };
      }
      if (step.id === downloadStepData?.id) {
        return {
          ...step,
          data: {
            url: data.data.file_url,
          },
        };
      }
      return step;
    });

    if (downloadStepData?.id) {
      await updateAgentSessionStep({
        ...downloadStepData,
        data: {
          url: data.data.file_url,
        },
      });
    }

    updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      message: "Error in downloading the file",
      title: "Error",
    });
  }
};

export const handleEndRiskSession = async (
  sessionId: string,
  navigate: NavigateFunction,
) => {
  const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();
  try {
    const agentData = getAgentData<
      AGENT_TYPES.RISK_ASSESSMENT,
      RiskAssessmentTypes.NIST_CSF_2
    >(sessionId);

    if (!agentData) {
      throw new Error("An error occurred");
    }

    const { stepData } = agentData;

    const editResponseStep = stepData?.find(
      (step) => step.type === AgentSessionStepType.EDIT_RESPONSE,
    );

    //if user directly clicks on end session without download report
    if (editResponseStep?.status != AgentSessionStatus.COMPLETE) {
      const risks = JSON.stringify(generateFinalRiskJSON(sessionId));
      const blob = new Blob([risks], { type: "application/json" });

      const signedUrl = await getSignedUrl({
        file_names: ["updated_risks.json"],
        max_age: 86400,
      });

      await uploadFileReq(signedUrl[0], blob);

      const editResponseStep = stepData?.find(
        (step) => step.type === AgentSessionStepType.EDIT_RESPONSE,
      );

      // Process the JSON file first
      await processFileForRiskReview(signedUrl[0], sessionId);

      const data = await getRAGExcelFromJSON({
        task: "nist_csf_2_risk_assessment",
        output_format: "excel",
        json_url: signedUrl[0],
        response_quality: agentData.responseQuality ?? AiResponseType.LITE,
      });
      setFinalFileUrl(sessionId, data.data.file_url);

      const downloadStepData = stepData?.find(
        (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
      );

      const updatedStepData = stepData.map((step) => {
        if (step.id === editResponseStep?.id) {
          return {
            ...step,
            status: AgentSessionStatus.COMPLETE,
            data: {
              url: signedUrl[0], //  JSON URL for future processing
            },
          };
        }
        if (step.id === downloadStepData?.id) {
          return {
            ...step,
            data: {
              url: data.data.file_url,
            },
            status: AgentSessionStatus.COMPLETE,
          };
        }
        return step;
      });

      if (downloadStepData?.id) {
        await updateAgentSessionStep({
          ...downloadStepData,
          data: {
            url: data.data.file_url,
          },
          status: AgentSessionStatus.COMPLETE,
        });
      }
      updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    } else {
      const prepareReportStep = stepData?.find(
        (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
      );

      const updatedStepData = stepData.map((step) => {
        if (step.id === prepareReportStep?.id) {
          return { ...step, status: AgentSessionStatus.COMPLETE };
        }
        return step;
      });

      if (prepareReportStep?.id) {
        await updateAgentSessionStep({
          ...prepareReportStep,
          status: AgentSessionStatus.COMPLETE,
        });
      }
      await new Promise<void>((resolve) => {
        updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
        setTimeout(resolve, 0);
      });
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    }
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      message: "Error in ending the session",
      title: "Error",
    });
  }
};
