/* eslint-disable @typescript-eslint/unbound-method */
import { get } from "@/infra/rest";
import { getSessionJWT } from "@/infra/stytch";
import { createAgentSession, getAgentSession, updateAgentSessionStep } from "@/modules/sessions/requests";
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  CommonRagCreateRequest,
  SOC2GapAgentSession,
} from "@/modules/sessions/types";
import { AGENT_ROUTES, ROUTES } from "@/shared/constants/routes";
import { getSignedUrl } from "@/shared/requests/get-signed-url";
import { uploadFileReq } from "@/shared/requests/upload-file";
import { addNotification } from "@/shared/states/notification";
import { userStateSelector } from "@/shared/states/user";
import { AiResponseType } from "@/shared/types/user";
import { NavigateFunction } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { emitRagCreate } from "../requests";
import { getRAGExcelFromJSON } from "../requests/";
import { getAgentData, getAgentStateActions } from "../states";
import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  AgentSpecificReviewResponseType,
  ComplianceStatus,
  ConfidenceTypes,
  GapAssessmentTypes,
  ReviewResponseData,
  ReviewSourceTypes,
  SOC2ComplianceStatusDeprecated,
  Soc2GapReviewResponseTableRow,
} from "../types";
import { ResponseSchemaVersion, SOC2GapReviewResponse, TestResultsValue } from "../types/risk-and-gap.ts";
import { AutoSaveFunctionArgs } from "../utils/autosave";
import { getFileNameForDownloadReport } from "../utils/downloadReport.ts";
import { getRenderType } from "../utils/get-render-type";
import { getResponseTypes } from "../utils/get-response-type";
import { convertSnakeToCapitalized } from "../utils/snake-to-capital.ts";
import { handleSocketResponse } from "../utils/socket-response";
import { processFileForCustomizeControls } from "./customize-control.use-case";

interface ProcessFileForSoc2GapAssessmentArgs {
  name: string;
  sourceFiles: AgentSourceFile[];
  navigate: NavigateFunction;
  customize: boolean;
  sessionType: AgentSessionType.SOC2_GAP_TYPE1 | AgentSessionType.SOC2_GAP | AgentSessionType.SOC2_GAP_TYPE2;
}

export const processFileForSoc2GapAssessment = async ({
  name,
  sourceFiles,
  customize,
  navigate,
  sessionType,
}: ProcessFileForSoc2GapAssessmentArgs) => {
  const agentSessionType = sessionType;
  const selectedResponse =
    userStateSelector.getState().aiResponseType === AiResponseType.NONE
      ? AiResponseType.LITE
      : userStateSelector.getState().aiResponseType;
  const source_urls = sourceFiles.map((file) => file.url);

  const { response_mode, response_quality } = getResponseTypes(selectedResponse);

  const gapAssessmentRequest: CommonRagCreateRequest = {
    doc_type: "json",
    response_quality,
    source_urls,
  };

  const {
    data: { session, steps = [] },
  } = await createAgentSession({
    name: name ?? "SOC 2 Gap Assessment",
    type: agentSessionType,
    customize,
    [agentSessionType]: gapAssessmentRequest,
  });

  if (!session || !session.id) {
    throw new Error("An error occurred");
  }

  const agentType = AGENT_TYPES.GAP_ASSESSMENT;
  const agentSubType =
    session.type === AgentSessionType.SOC2_GAP_TYPE1
      ? GapAssessmentTypes.SOC2_TYPE1
      : session.type === AgentSessionType.SOC2_GAP_TYPE2
        ? GapAssessmentTypes.SOC2_TYPE2
        : GapAssessmentTypes.SOC2;
  const statusInProgressSessionStep = AgentSessionStepType.SOC2_TYPE2_AUDIT;
  const task = "soc2_type2_audit";
  const statusCompleteSessionSteps = [AgentSessionStepType.LOAD_TEMPLATE, AgentSessionStepType.EXTRACT_CONTROLS];

  const stepData = steps.reduce((acc, step) => {
    if (statusCompleteSessionSteps.includes(step.type)) {
      step.status = AgentSessionStatus.COMPLETE;
    }

    if (statusInProgressSessionStep === step.type) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }

    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  const customizeControlStep = stepData?.find((step) => step.type === AgentSessionStepType.CONTROL_SELECTION);

  const agentData: AgentData<typeof agentType, typeof agentSubType> = {
    agentType: agentType,
    sessionData: session as SOC2GapAgentSession,
    stepData,
    subType: agentSubType,
    responseQuality: selectedResponse,
    mainData: {
      sourceFilesUrls: new Map(sourceFiles.map((file) => [file.fileName, file])),
      approvedIds: [],
      editedIds: [],
    },
  };

  const agent_session_step_id =
    steps.find((step) => step.type === statusInProgressSessionStep)?.id ??
    steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE)?.id ??
    "";

  const { setAgentData } = getAgentStateActions();
  setAgentData(session.id, agentData);

  if (customizeControlStep && customizeControlStep.status === AgentSessionStatus.INPUT_NEEDED) {
    await processFileForCustomizeControls(customizeControlStep.data.url);
  } else {
    await emitRagCreate(
      {
        agent_session_id: session.id,
        agent_session_step_id,
        token: await getSessionJWT(),
        rag_input: gapAssessmentRequest,
        response_mode,
        task: task,
      },
      (response: any) => {
        handleSocketResponse(response, navigate);
      },
    );
  }
  navigate({
    pathname: `/agent/${session.id}/`,
  });
};

interface FinalGapReviewResponse extends SOC2GapReviewResponse {
  tsc_id: string;
  approved: boolean;
  edited: boolean;
  id: string;
}

export const generateFinalSoc2GapJSON = (agentId: string) => {
  const agentData = getAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(agentId);

  if (!agentData) {
    throw new Error("An error occurred");
  }
  const { mainData } = agentData;
  const { reviewResponseData } = mainData;
  if (!reviewResponseData) {
    throw new Error("An error occurred");
  }
  const approvedIds = mainData.approvedIds;
  const editedIds = mainData.editedIds;
  const finalGapJson: FinalGapReviewResponse[] = [];

  reviewResponseData.forEach((item, id) => {
    const gap: FinalGapReviewResponse = {
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
      tsc_id: item.find((item) => item.key === "tsc_id")?.value as string,
      id,
      gaps: item.find((item) => item.key === "gaps")?.value as string,
      recommendations: item.find((item) => item.key === "recommendations")?.value as string,
      confidence: item.find((item) => item.key === "confidence")?.value as ConfidenceTypes,
      justification: item.find((item) => item.key === "justification")?.value as string,
      sources: item.find((item) => item.key === "sources")?.value as ReviewSourceTypes[],
      observations: item.find((item) => item.key === "observations")?.value as string,
      compliance_status: item.find((item) => item.key === "compliance_status")?.value as SOC2ComplianceStatusDeprecated,
      llm_instructions: item.find((item) => item.key === "llm_instructions")?.value as string,
      trust_id: item.find((item) => item.key === "trust_id")?.value as string,
      tsc: item.find((item) => item.key === "tsc")?.value as string,
      control_number: item.find((item) => item.key === "control_number")?.value as number,
      control: item.find((item) => item.key === "control")?.value as string,
      category: item.find((item) => item.key === "category")?.value as string,
      assessment_criteria: item.find((item) => item.key === "assessment_criteria")?.value as string[],
      retriever_questions: item.find((item) => item.key === "retriever_questions")?.value as string[],
      zania_control_id: item.find((item) => item.key === "zania_control_id")?.value as string,
      control_id: item.find((item) => item.key === "control_id")?.value as string,
      improvement_opportunities: item.find((item) => item.key === "improvement_opportunities")?.value as string,
      improvement_recommendations: item.find((item) => item.key === "improvement_recommendations")?.value as string,
      toe: item.find((item) => item.key === "toe")?.value as string,
      tod: item.find((item) => item.key === "tod")?.value as string,
      criteria: item.find((item) => item.key === "criteria")?.value as string,
      version: item.find((item) => item.key === "version")?.value as ResponseSchemaVersion,
      test_results: item.find((item) => item.key === "test_results")?.value as TestResultsValue[],
    };
    finalGapJson.push(gap);
  });

  return finalGapJson;
};

export const handleAutoSaveSoc2 = async ({ agentId, markAsComplete }: AutoSaveFunctionArgs) => {
  const risks = JSON.stringify(generateFinalSoc2GapJSON(agentId));
  const blob = new Blob([risks], { type: "application/json" });
  const agentData = getAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(agentId);

  if (!agentData) {
    throw new Error("An error occurred");
  }

  const { stepData } = agentData;
  const { staleUrl } = agentData.mainData;

  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();

  const editStepData = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

  if (!editStepData) {
    throw new Error("An occurred while saving");
  }

  const stepUrl = editStepData?.data?.url;

  let currentStaleUrl = staleUrl || stepUrl;
  let markAsCompleteDone = false;

  if (!currentStaleUrl) {
    const signedUrl = await getSignedUrl({
      file_names: ["updated_gaps.json"],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete ? AgentSessionStatus.COMPLETE : AgentSessionStatus.INPUT_NEEDED,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }
  const expiryDate = new URL(currentStaleUrl).searchParams.get("se");
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }
  await uploadFileReq(currentStaleUrl, blob);
  if (markAsComplete && !markAsCompleteDone) {
    const updatedStep = {
      ...editStepData,
      data: {
        url: currentStaleUrl,
      },
      status: AgentSessionStatus.COMPLETE,
    };
    await updateAgentSessionStep(updatedStep);
    const updatedStepData = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedStepData);
  }
};

export const processFileForSOC2Type2Audit = async (url: string, sessionId: string) => {
  const gapJson = await get<FinalGapReviewResponse[]>({
    url,
    isAuthRequired: false,
  });

  // Sort gapJson in ascending order of trust_id

  const session = await getAgentSession(sessionId ?? "");

  let versionScheme: ResponseSchemaVersion | undefined = undefined;
  if (session.data.session.type !== AgentSessionType.SOC2_GAP) {
    versionScheme = "v2";
  }

  const ids: string[] = [];
  const keysToOmit: string[] = [
    "id",
    "confidence",
    "approved",
    "edited",
    "llm_instructions",
    "trust_id",
    "control_number",
    "control",
    "category",
    "assessment_criteria",
    "retriever_questions",
    "control_id",
    "zania_control_id",
    "justification",
    "tsc_id",
    "tsc",
    "uid",
    "criteria",
    "testing_procedures",
    "version",
    session.data.session.type !== AgentSessionType.SOC2_GAP_TYPE2 ? "toe" : "tod",
    "edited_state",
    "evidence",
  ];
  const tableRows: Soc2GapReviewResponseTableRow[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];
  const reviewResponse = new Map<
    string,
    ReviewResponseData<keyof AgentSpecificReviewResponseType<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>>[]
  >(
    gapJson.map((gap) => {
      const id = uuid();
      ids.push(id);
      const tableRow: Soc2GapReviewResponseTableRow = {
        id: id,
        confidence: gap.confidence ?? "",
        sources: gap.sources ?? [],
        tag: gap.trust_id ?? gap.tsc_id ?? "",
        control: gap.control ?? "",
        compliant: (gap.compliance_status ? gap.compliance_status.replace(/ /g, "-") : "") as ComplianceStatus,
        status: gap.approved ? "approved" : gap.edited ? "edited" : "none",
      };
      tableRows.push(tableRow);
      if (gap.approved) {
        approvedIds.push(id);
      }
      if (gap.edited) {
        editedIds.push(id);
      }

      const responseData = Object.entries({ ...gap }).reduce(
        (acc, [key, value]) => {
          const data: ReviewResponseData<
            keyof AgentSpecificReviewResponseType<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>
          > = {
            type: getRenderType(key, versionScheme ?? (gap.version as ResponseSchemaVersion)),
            value: value,
            key: key as keyof AgentSpecificReviewResponseType<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>,
            title: key === "toe" || key === "toe" ? "Testing Details" : convertSnakeToCapitalized(key), // use this instead of getFieldTitle because accoding to figma ui we dont need to other reference or reference
            shouldRender: !keysToOmit.includes(key),
          };
          acc.push(data);
          return acc;
        },
        [] as ReviewResponseData<
          keyof AgentSpecificReviewResponseType<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>
        >[],
      );

      // order of things testing procedure -> observation -> gaps -> improvement oppurtunies -> test results -> compliance status -> sources

      const orderedKeys = [
        session.data.session.type === AgentSessionType.SOC2_GAP_TYPE2 ? "toe" : "tod",
        "observations",
        "gaps",
        "recommendations",
        "improvement_opportunities",
        "improvement_recommendations",
        "test_results",
        "compliance_status",
        "sources",
      ];

      responseData.sort((a, b) => {
        const indexA = orderedKeys.indexOf(a.key as string);
        const indexB = orderedKeys.indexOf(b.key as string);
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        return 0;
      });

      return [id, responseData];
    }),
  );

  tableRows.sort((a, b) => {
    const trustIdA = a.tag || "";
    const trustIdB = b.tag || "";

    const [categoryA, numberA] = trustIdA.split(".");
    const [categoryB, numberB] = trustIdB.split(".");

    const categoryComparison = categoryA.localeCompare(categoryB);
    if (categoryComparison !== 0) {
      return categoryComparison;
    }

    return Number(numberA) - Number(numberB);
  });

  const currentData = getAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(sessionId);
  if (!currentData) {
    throw new Error("An error occurred");
  }

  const stepData = currentData.stepData.map((step) => {
    if (
      [
        AgentSessionStepType.SOC2_TYPE2_AUDIT,
        AgentSessionStepType.SOC2_TYPE1,
        AgentSessionStepType.SOC2_TYPE2,
      ].includes(step.type)
    ) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
    }
    return step;
  });
  const agentSubType =
    session.data.session.type === AgentSessionType.SOC2_GAP_TYPE1
      ? GapAssessmentTypes.SOC2_TYPE1
      : session.data.session.type === AgentSessionType.SOC2_GAP_TYPE2
        ? GapAssessmentTypes.SOC2_TYPE2
        : GapAssessmentTypes.SOC2;
  const agentData: Partial<
    AgentData<
      AGENT_TYPES.GAP_ASSESSMENT,
      GapAssessmentTypes.SOC2 | GapAssessmentTypes.SOC2_TYPE1 | GapAssessmentTypes.SOC2_TYPE2
    >
  > = {
    agentType: AGENT_TYPES.GAP_ASSESSMENT,
    subType: agentSubType,
    sessionData: session.data.session as SOC2GapAgentSession,
    stepData,
    mainData: {
      ...currentData.mainData,
      reviewResponseData: reviewResponse,
      reviewResponseIds: ids,
      tableRows,
      searchFor: ["control"],
      searchTerm: "",
      approvedIds,
      editedIds,
    },
  };

  const { updateAgentData } = getAgentStateActions();
  updateAgentData<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.SOC2 | GapAssessmentTypes.SOC2_TYPE1 | GapAssessmentTypes.SOC2_TYPE2
  >(sessionId, agentData);
};

export const getSoc2JsonFromApi = async (sessionId: string, responsesJsonFileUrl: string) => {
  try {
    const agentData = getAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(sessionId);
    if (!agentData) {
      throw new Error("An error occurred");
    }
    const { responseQuality, sessionData, stepData } = agentData;

    const data = await getRAGExcelFromJSON({
      task: agentData.sessionData.type,
      output_format: "excel",
      json_url: responsesJsonFileUrl,
      response_quality: responseQuality ?? AiResponseType.LITE,
    });

    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    setFinalFileUrl(sessionId, data.data.file_url);

    const response = await fetch(data.data.file_url);
    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = getFileNameForDownloadReport(sessionData);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: "success",
      title: "Downloading your Report...",
      message: "If your report download does not start within 15 seconds, please use the download button",
    });

    const downloadStepData = stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);

    const editResponseStep = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);
    const updatedStepData = stepData.map((step) => {
      if (step.id === editResponseStep?.id) {
        return { ...step, status: AgentSessionStatus.COMPLETE };
      }
      if (step.id === downloadStepData?.id) {
        return {
          ...step,
          data: {
            url: data.data.file_url,
          },
        };
      }
      return step;
    });

    if (downloadStepData?.id) {
      await updateAgentSessionStep({
        ...downloadStepData,
        data: {
          url: data.data.file_url,
        },
      });
    }

    updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      message: "Error in downloading the file",
      title: "Error",
    });
  }
};

export const handleEndSoc2Session = async (sessionId: string, navigate: NavigateFunction) => {
  try {
    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();
    const agentData = getAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.SOC2>(sessionId);
    if (!agentData) {
      throw new Error("An error occurred");
    }

    const { stepData } = agentData;

    const editResponseStep = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

    //if user directly clicks on end session without download report
    if (editResponseStep?.status != AgentSessionStatus.COMPLETE) {
      const gaps = JSON.stringify(generateFinalSoc2GapJSON(sessionId));
      const blob = new Blob([gaps], { type: "application/json" });

      const signedUrl = await getSignedUrl({
        file_names: ["updated_gap.json"],
        max_age: 86400,
      });

      await uploadFileReq(signedUrl[0], blob);
      const editResponseStep = stepData?.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE);

      // Process the JSON file first
      await processFileForSOC2Type2Audit(signedUrl[0], sessionId);

      const data = await getRAGExcelFromJSON({
        task: "soc2_type2_audit",
        output_format: "excel",
        json_url: signedUrl[0],
        response_quality: agentData.responseQuality ?? AiResponseType.LITE,
      });

      setFinalFileUrl(sessionId, data.data.file_url);

      const downloadStepData = stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);

      const updatedStepData = stepData.map((step) => {
        if (step.id === editResponseStep?.id) {
          return {
            ...step,
            status: AgentSessionStatus.COMPLETE,
            data: {
              url: signedUrl[0], //  JSON URL for future processing
            },
          };
        }
        if (step.id === downloadStepData?.id) {
          return {
            ...step,
            data: {
              url: data.data.file_url,
            },
            status: AgentSessionStatus.COMPLETE,
          };
        }
        return step;
      });

      if (downloadStepData?.id) {
        await updateAgentSessionStep({
          ...downloadStepData,
          data: {
            url: data.data.file_url,
          },
          status: AgentSessionStatus.COMPLETE,
        });
      }

      updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    } else {
      const prepareReportStep = stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT);

      const updatedStepData = stepData.map((step) => {
        if (step.id === prepareReportStep?.id) {
          return { ...step, status: AgentSessionStatus.COMPLETE };
        }
        return step;
      });

      if (prepareReportStep?.id) {
        await updateAgentSessionStep({
          ...prepareReportStep,
          status: AgentSessionStatus.COMPLETE,
        });
      }

      await new Promise<void>((resolve) => {
        updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
        setTimeout(resolve, 0);
      });

      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    }
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      message: "Error in ending the session",
      title: "Error",
    });
  }
};
