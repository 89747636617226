import { getSignedUrl } from "@/shared/requests/get-signed-url";

// Before rendering source, check and refresh URL if needed
export const checkAndRefreshUrl = async (url: string): Promise<string> => {
  try {
    if (url.startsWith("https://publicassetszania.blob.core.windows.net/")){
      return url;
    }

    const expiryDate = new URL(url).searchParams.get("se");
    if (expiryDate) {
      // if it is a signed url, with the query params intact
      const expiry = new Date(expiryDate);
      const currentTime = new Date();
      const diff = expiry.getTime() - currentTime.getTime();

      // Refresh if URL is valid for more than 5 mins
      if (diff > 300000) {
        return url;
      }
    } 

    const signedUrl = await getSignedUrl({
      stale_urls: [url],
      max_age: 86400,
    });
    return signedUrl[0];
  } catch (error) {
    console.error("Error refreshing URL:", error);
    return url;
  }
};