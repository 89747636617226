import { shouldEnableInsights } from "@/main";
import {
  useAuthActions,
  useIsAuthenticated,
  useIsAuthenticationInProgress,
} from "@/modules/auth/states";
import { useEffect, useMemo, useState } from "react";
import { AppProtectedRoutes, AppUnprotectedRoutes } from "../routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ZaniaLogoLoader from "@components/page-loader";
import useLoadDataOnAppLoad from "@/shared/hooks/use-load-data-start";
import { Worker } from "@react-pdf-viewer/core";
import { version as pdfJsVersion } from "pdfjs-dist";
import { checkValidDomain } from "@/shared/utils/organization-helper";
import { setLoginStep, useLoginStep } from "@/modules/auth/states/login";
import { LOGIN_STEP } from "@/modules/auth/types";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticationInProgress = useIsAuthenticationInProgress();
  const { setAuth } = useAuthActions();
  const [isSessionChecked, setIsSessionChecked] = useState(false);

  const loginStep = useLoginStep();

  const {
    stytch: stytchClient,
    member,
    organization,
  } = useLoadDataOnAppLoad(isAuthenticated);

  const routes = useMemo(() => {
    return isAuthenticated &&
      !isAuthenticationInProgress &&
      loginStep !== LOGIN_STEP.SELECT_ORG
      ? AppProtectedRoutes
      : AppUnprotectedRoutes;
  }, [isAuthenticated, isAuthenticationInProgress, loginStep]);

  const router = useMemo(() => {
    return createBrowserRouter(routes);
  }, [routes]);

  useEffect(() => {
    const checkSession = async () => {
      const { session, fromCache } = stytchClient.session.getInfo();

      // Below logic for the checking the valid domain of the user on refresh
      const emailID =
        member && member.member && member.member.email_address !== ""
          ? member.member.email_address
          : "";
      let allowedDomains =
        organization &&
        organization.organization &&
        organization.organization.email_allowed_domains.length > 0
          ? [...organization.organization.email_allowed_domains]
          : [];

      if (organization.organization === null && session) {
        const org = await stytchClient.organization.get();

        allowedDomains = [
          ...allowedDomains,
          ...(org?.email_allowed_domains ?? []),
        ];
      }

      // End of the above logic

      if (session) {
        if (!checkValidDomain(emailID, allowedDomains)) {
          setAuth({
            isAuthenticated: false,
            isAuthenticationInProgress: false,
          });
          setLoginStep(LOGIN_STEP.ACCESS_RESTRICTED);
          return;
        }

        if (
          loginStep !== LOGIN_STEP.SELECT_ORG &&
          loginStep !== LOGIN_STEP.AUTHENTICATING
        ) {
          setAuth({
            isAuthenticated: true,
            isAuthenticationInProgress: fromCache,
          });
        }
      } else {
        setAuth({
          isAuthenticated: false,
          isAuthenticationInProgress: false,
        });
      }
      setIsSessionChecked(!fromCache);
    };

    checkSession();

    const unsubscribe = stytchClient.session.onChange(() => {
      const { session, fromCache } = stytchClient.session.getInfo();
      if (
        session &&
        loginStep !== LOGIN_STEP.SELECT_ORG &&
        loginStep !== LOGIN_STEP.AUTHENTICATING
      ) {
        setAuth({
          isAuthenticated: true,
          isAuthenticationInProgress: fromCache,
        });
      } else {
        setAuth({
          isAuthenticated: false,
          isAuthenticationInProgress: false,
        });
      }
      setIsSessionChecked(!fromCache);
    });

    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, setAuth, stytchClient, member, organization, loginStep]);

  if (isAuthenticationInProgress || !isSessionChecked) {
    return <ZaniaLogoLoader />;
  }

  return (
    <Worker
      workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJsVersion}/pdf.worker.min.js`}
    >
      <RouterProvider router={router} fallbackElement={<ZaniaLogoLoader />} />
    </Worker>
  );
}

export default App;
